import {
    canViewTasksSelector,
    currentLanguageSelector,
    currentUserSiteLocation,
    isApocSelector,
} from 'aos-services/src/core/auth/state'
import { useKeycloak } from 'aos-services/src/providers/keycloak'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { AppContainer } from 'aos-ui/src/components/appcontainer/AppContainer'
import { Spinner } from 'aos-ui/src/components/ui/Spinner'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import { Router } from 'react-router-dom'

import { LVPCounter } from '../components/lvp-counter/LVPCounter'
import { Link } from '../core/Links'
import { history } from '../core/store'
import { toggleUserProfileModalAction } from '../core/userProfileModal/actions'
import { ActionModals } from './actionModal/ActionModals'
import { AirportMap } from './airportMap/AirportMap'
import ApocRoute from './ApocRoute'
import { App } from './App'
import { ChecklistManager } from './checklistManager/ChecklistManager'
import { ContactList } from './contactList/ContactList'
import { DiscountModule } from './discountModule/DiscountModule'
import { DiscountModuleModals } from './discountModule/modals'
import { EventEditor } from './eventEditor/EventEditor'
import { EventManager } from './eventManager/EventManager'
import { EventModals } from './eventModals/EventModals'
import { EventPreview } from './eventPreview/EventPreview'
import { EventTimeline } from './eventTimeline/EventTimeline'
import { FlightInformation } from './flightInformation/FlightInformation'
import FodModals from './fod/FodModals'
import { GroupManager } from './groupManager/GroupManager'
import { UserProfilePreferencesModal } from './profile/UserPreferenceModal'
import { RemoveAccount } from './removeAccount/RemoveAccount'
import { ReportingModal } from './reporting/ReportingModal'
import { StatusDashboard } from './statusDashboard/StatusDashboard'
import { FirefightersModal } from './tasks/firefighters/FirefightersModal'
import { ShiftNotesEditModal } from './tasks/shiftNotes/ShiftNotesEditModal'
import { TasksModals } from './tasks/TasksModals'
import { TasksRouter } from './tasks/TasksRouter'
import { WhatsNewModal } from './whatsNew/WhatsNewModal'

export const AppRouter = () => {
    const language = useSelector(currentLanguageSelector)
    const isApoc = useSelector(isApocSelector)
    const canViewTasks = useSelector(canViewTasksSelector)
    const location = useSelector(currentUserSiteLocation)
    const dispatch = useDispatch()
    const { keycloak } = useKeycloak()

    return keycloak?.authenticated ? (
        <Router history={history}>
            <AppContainer key={language}>
                <Switch>
                    <Route path={Link.RemoveAccount} render={() => <RemoveAccount />} />
                    <App>
                        <Route
                            exact
                            path={Link.StatusDashboardPreset}
                            render={() => <StatusDashboard />}
                        />
                        <Route exact path={Link.EventTimeline} render={() => <EventTimeline />} />
                        <Route exact path={Link.Map} component={AirportMap} />
                        <ApocRoute
                            exact
                            path={Link.EventManager}
                            isApoc={isApoc}
                            component={() => <EventManager />}
                        />
                        <ApocRoute
                            exact
                            path={Link.GroupManager}
                            isApoc={isApoc}
                            component={() => <GroupManager />}
                        />
                        <Route path={Link.EventManagerItem} component={EventEditor} />
                        {(canViewTasks || isApoc) && (
                            <Route
                                exact
                                path={Link.ChecklistManager}
                                component={ChecklistManager}
                            />
                        )}
                        <Route path={Link.Flights} component={FlightInformation} />
                        <Route
                            exact
                            path={Link.NotificationPreferences}
                            render={() => {
                                dispatch(toggleUserProfileModalAction(true))
                                return <Redirect to={Link.StatusDashboardOverview} />
                            }}
                        />
                        {canViewTasks && <Route path={Link.Tasks} component={TasksRouter} />}
                        <Route path={Link.ContactList} render={() => <ContactList />} />
                        {location === AosAirport.HEL && (
                            <Route path={Link.DiscountModule} render={() => <DiscountModule />} />
                        )}
                        <Route render={() => <Redirect to={Link.StatusDashboardOverview} />} />
                        <Route
                            exact
                            path='/'
                            render={() => <Redirect to={Link.StatusDashboardOverview} />}
                        />
                    </App>
                </Switch>
                <EventModals />
                <EventPreview />
                <ActionModals />
                <WhatsNewModal />
                <UserProfilePreferencesModal />
                <TasksModals />
                <FodModals />
                <ShiftNotesEditModal />
                <FirefightersModal />
                <ReportingModal />
                <DiscountModuleModals />

                <LVPCounter />
            </AppContainer>
        </Router>
    ) : (
        <Spinner />
    )
}
