import { DateTime } from 'aos-helpers/src/helpers/Time'
import { TimelineState } from 'aos-services/src/core/timeline/state'
import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import { Moment } from 'moment/moment'
import { rgba } from 'polished'
import React, { FC, ReactNode, useRef } from 'react'
import ReactTimeline, {
    CustomMarker,
    GroupWrapper,
    ItemWrapper,
    TimeCallback,
    TodayMarker,
} from 'react-calendar-timeline'
import styled from 'styled-components'

import { translate } from '../../../../aos-helpers/src/helpers/translations/Translations'
import { Text } from '../base/Text'
import { boxShadow } from '../base/Theme'
import { TimelineGroupRenderer } from './base/TimelineGroupRenderer'
import { TimelineItemContentProps } from './base/TimelineItem'
import { TimelineItemRenderer } from './base/TimelineItemRenderer'
import { BaseCalendarTimelineGroup } from './types/BaseCalendarTimelineGroup'
import { BaseCalendarTimelineItem } from './types/BaseCalendarTimelineItem'

/* eslint-disable react/no-unused-prop-types */
interface TimelineProps<T extends BaseCalendarTimelineItem, G extends BaseCalendarTimelineGroup> {
    items: T[]
    groups: G[]
    timeline: TimelineState
    onTimeChange: TimeCallback
    currentTime: DateTime
    GroupRenderer?: (props: GroupWrapper<G>) => React.ReactElement
    ContentRenderer: (props: TimelineItemContentProps<T>) => React.ReactElement
    withSidebar?: boolean
    itemHeightRatio?: number
    openItem(item: T): void
    toggleGroup(id: G): void
    utc?: boolean
    withTooltip?: boolean
}

export const Timeline = <
    T extends BaseCalendarTimelineItem = BaseCalendarTimelineItem,
    G extends BaseCalendarTimelineGroup = BaseCalendarTimelineGroup,
>(
    props: TimelineProps<T, G>,
) => {
    const container = useRef<HTMLDivElement>(null)
    const {
        toggleGroup,
        items,
        timeline,
        groups,
        openItem,
        GroupRenderer,
        ContentRenderer,
        withSidebar = true,
        withTooltip = true,
        itemHeightRatio = 0.8,
        currentTime,
        utc,
    } = props
    const keysSpec = {
        itemTimeStartKey: 'startTime',
        itemTimeEndKey: 'endTime',
        itemGroupKey: 'group',
        groupIdKey: 'id',
        itemIdKey: 'id',
        groupTitleKey: 'id',
        itemTitleKey: 'id',
    }
    const defaultGroupRenderer = (item: GroupWrapper<G>): ReactNode => (
        <TimelineGroupRenderer group={item.group} toggleGroup={toggleGroup} />
    )
    const onTimeChange = (start: number, end: number, updateScrollCanvas: TimeCallback) => {
        updateScrollCanvas(start, end)
        props.onTimeChange(start, end)
    }

    const renderItem = (item: ItemWrapper<T>) => (
        <TimelineItemRenderer
            withTooltip={withTooltip}
            container={container.current as Element}
            item={item.item}
            dimensions={item.itemContext.dimensions}
            timelineContext={item.timelineContext}
            openItem={openItem}
            itemProps={item.getItemProps(undefined)}
            isCollapsed={item.item.isCollapsed}
            ContentRenderer={ContentRenderer}
        />
    )
    return (
        <ReactTimelineContainer ref={container}>
            <ReactTimeline
                groups={groups || []}
                items={items}
                visibleTimeStart={timeline.visibleTimeStart}
                visibleTimeEnd={timeline.visibleTimeEnd}
                stackItems
                sidebarWidth={withSidebar ? 96 : 0}
                sidebarContent={<Box />}
                headerLabelGroupHeight={32}
                headerLabelHeight={32}
                itemRenderer={renderItem}
                groupRenderer={GroupRenderer || defaultGroupRenderer}
                itemHeightRatio={itemHeightRatio}
                lineHeight={50}
                fixedHeader='fixed'
                canMove={false}
                canResize={false}
                canChangeGroup={false}
                onTimeInit={props.onTimeChange}
                onTimeChange={onTimeChange}
                minZoom={timeline.minZoom}
                maxZoom={timeline.maxZoom}
                keys={keysSpec}
                className='timeline'
            >
                {utc ? <UTCTime date={currentTime} /> : <TodayMarker />}
            </ReactTimeline>
        </ReactTimelineContainer>
    )
}

const UTCTime: FC<{ date: Moment }> = ({ date }) => {
    return (
        <CustomMarker date={date}>
            {({ styles }) => {
                const customStyles = {
                    ...styles,
                    backgroundColor: 'none',
                }
                const topStyle = {
                    height: '20px',
                    width: '2px',
                    backgroundColor: styles.backgroundColor,
                }

                const bottomStyle = {
                    height: '100%',
                    width: '2px',
                    backgroundColor: styles.backgroundColor,
                }
                return (
                    <Box style={customStyles} rowGap={4} column alignItems='center'>
                        <Box style={topStyle} />
                        <Box
                            style={{ boxShadow: boxShadow.std }}
                            bg={Color.ChartBase}
                            padding={4}
                            rounded
                        >
                            <Text size={12} color={styles.backgroundColor as Color}>
                                {translate('UTC')}
                            </Text>
                        </Box>
                        <Box style={bottomStyle} />
                    </Box>
                )
            }}
        </CustomMarker>
    )
}

export const ReactTimelineContainer = styled(Box)`
    * {
        box-sizing: border-box;
    }
    .rct-outer {
        display: block;
        overflow: hidden;
        white-space: nowrap;
    }

    .rct-scroll {
        display: inline-block;
        white-space: normal; // was set to nowrap in .rct-outer
        vertical-align: top;
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-touch-action: none;
        touch-action: none;
    }

    .rct-item {
        &:hover {
            z-index: 88;
        }
        .rct-item-content {
            position: sticky;
            position: -webkit-sticky;
            left: 0;
            overflow: hidden;
            display: inline-block;
            border-radius: 2px;
            padding: 0 6px;
            height: 100%;
        }
    }

    .rct-header-container {
        z-index: 90;
        display: flex;
        overflow: hidden;

        &.header-sticky {
            position: sticky;
            position: -webkit-sticky;
        }
    }

    .rct-header {
        margin: 0;
        overflow-x: hidden;
        z-index: 90;
        background: ${Color.WidgetBackground};

        .rct-top-header,
        .rct-bottom-header {
            position: relative;
        }

        .rct-label-group {
            padding: 0 5px;
            position: absolute;
            top: 0;
            font-size: 13px;
            text-align: center;
            cursor: pointer;
            border-left: 1px solid ${Color.ChartBase};
            color: ${Color.MenuButtonColor};
            background: ${Color.WidgetBackground};
            border-bottom: 1px solid ${Color.ChartBase};
            &.rct-has-right-sidebar {
                border-right: 0.5px solid ${Color.ChartBase};
                border-left: 0.5px solid ${Color.ChartBase};
            }

            & > span {
                position: sticky;
                left: 5px;
                right: 5px;
            }
        }

        .rct-label {
            position: absolute;
            // overflow: hidden;
            text-align: center;
            cursor: pointer;
            border-left: 1px solid ${Color.ChartBase};
            color: ${Color.MenuButtonColor};
            background: ${Color.WidgetBackground};
            border-bottom: 1px solid ${Color.ChartBase};

            &.rct-label-only {
                color: ${Color.MenuButtonColor};
                background: ${Color.WidgetBackground};
            }

            &.rct-first-of-type {
                border-left: 1px solid ${Color.ChartBase};
            }
        }
    }

    .rct-sidebar-header {
        margin: 0;
        color: ${Color.White};
        background: ${Color.WidgetBackground};
        border-right: 1px solid ${Color.ChartBase};
        box-sizing: border-box;
        border-bottom: 1px solid ${Color.ChartBase};
        overflow: hidden;

        &.rct-sidebar-right {
            border-right: 0;
            border-left: 1px solid ${Color.ChartBase};
        }
    }

    .rct-sidebar {
        overflow: hidden;
        white-space: normal; // was set to nowrap in .rct-outer
        display: inline-block;
        vertical-align: top;
        position: relative;
        box-sizing: border-box;
        border-right: 1px solid ${Color.ChartBase};

        &.rct-sidebar-right {
            border-right: 0;
            border-left: 1px solid ${Color.ChartBase};
        }

        .rct-sidebar-row {
            padding: 0 4px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            box-sizing: content-box;
            margin: 0;
            border-bottom: 1px solid ${Color.ChartBase};

            &.rct-sidebar-row-odd {
                background: ${Color.TimelineBackground};
            }
            &.rct-sidebar-row-even {
                background: ${Color.TimelineBackground};
            }
        }
    }

    .rct-vertical-lines {
        .rct-vl {
            position: absolute;
            border-left: 1px solid ${Color.ChartBase};
            z-index: 30;
            &.rct-vl-first {
                border-left-width: 1px;
            }
        }
    }

    .rct-horizontal-lines {
        .rct-hl-even,
        .rct-hl-odd {
            border-bottom: 1px solid ${Color.ChartBase};
            box-sizing: content-box;
            z-index: 40;
        }
        .rct-hl-odd {
            background: ${Color.TimelineBackground};
        }
        .rct-hl-even {
            background: ${Color.TimelineBackground};
        }
    }

    .rct-cursor-line {
        position: absolute;
        width: 2px;
        background: ${Color.Transparent};
        z-index: 51;
    }

    .rct-infolabel {
        position: fixed;
        left: 100px;
        bottom: 50px;
        background: ${rgba(Color.Black, 0.5)};
        color: white;
        padding: 10px;
        font-size: 20px;
        border-radius: 5px;
        z-index: 85;
    }
`
