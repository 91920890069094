import { Text, TextProps, textStyleBuilder } from 'aos-ui/src/components/base/Text'
import { boxShadow, ZIndex } from 'aos-ui/src/components/base/Theme'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled, { CSSObject } from 'styled-components'

export interface HeaderProps extends TextProps {
    variant?: HeaderVariant
    shadowed?: boolean
}

export enum HeaderVariant {
    Dialog = 1,
    Notifications,
    Modal,
    ModalIcon,
}

const variantStyles: Record<HeaderVariant, CSSObject> = {
    [HeaderVariant.Dialog]: {
        height: '76px',
    },
    [HeaderVariant.Notifications]: {
        height: '96px',
    },
    [HeaderVariant.Modal]: {
        height: '56px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
    },
    [HeaderVariant.ModalIcon]: {
        height: '200px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
    },
}

export const Header: FCWithChildren<PropsWithChildren<HeaderProps>> = ({
    variant,
    children,
    shadowed,
    ...rest
}) => (
    <HeaderBox as='header' variant={variant} shadowed={shadowed} row {...rest}>
        {children}
    </HeaderBox>
)

const HeaderBox = styled(Text)<HeaderProps>(({ variant = HeaderVariant.Dialog, shadowed }) => {
    const styles: CSSObject = {
        position: 'relative',
        width: '100%',
        backgroundColor: Color.PrimaryLight,
        zIndex: ZIndex.Header,
        flexShrink: 0,
        ...textStyleBuilder({ size: 18, color: Color.PrimaryButtonText, weight: 'light' }),
    }

    if (shadowed) {
        styles.boxShadow = boxShadow.large
    }
    return { ...styles, ...variantStyles[variant] }
})
