import { appConfig } from 'appConfig'
import { rgba } from 'polished'

const BaseColors = {
    Grey: '#69728B',
    GreyLight: '#abb4c9',
    Black: '#050a10',
    White: '#ffffff',

    Grey1: '#f7f7fa',
    Grey2: '#858891',
    Grey3: '#303d58',
    Grey4: '#B1B4BD',
    Grey5: '#677497',
    Grey6: '#707688',
    Grey7: '#34435F',
    Grey8: '#9BA2B0',
    Grey9: '#8599BB',

    TransparentWhite: '#FFFFFF44',
    Transparent: 'rgba(0,0,0,0)',
    TransparentBlack: 'rgba(0,0,0,0.6)',
    TransparentGrey: '#E5E6EA',

    Green: '#32a538',
    DarkGreen: '#0a6e2c',
    LightGreen: '#45eb4d',
    ToastGreen: '#409244',
    PaleGreen: '#a6d46d',
    VeryPaleOrange: '#F6F0E9',
    UltraPaleGreen: '#E9F6EA',
    SoftGreen: '#81c48d',
    Yellow: '#f8ba00',
    DarkYellow: '#664A02',
    PaleOrange: '#f1a267',
    Orange: '#ff6d00',
    Red: '#e42626',
    PaleRed: '#F6E9E9',
    DarkRed: '#280006',
    Magenta: '#da58ab',
    SoftMagenta: '#d958ab',
    Violet: '#6c55a7',
    SoftBlue: '#4c85ff',
    SeaBlue: '#4dc8c9',
    LightBlue: '#E9EEF6',
    PaleBlue: '#DFE9FF',
    TaskBlue: '#1A62FB',
    IntensiveBlue: '#1D62FB',
    TaskYellow: '#ffd145',
    DarkBlue: '#060B14',

    ProgressGreen: '#69F996',
    ProgressRed: '#FF073A',
    ProgressGrey: '#D9D9D9',
    ProgressYellow: '#FFB904',
    ProgressBarGradient: 'linear-gradient(180deg, rgba(108,255,156,1) 0%, rgba(50,165,56,1) 100%)',
    UnitTaskBlack: '#16181C',
    UnitTaskYellow: '#332500',
    UnitTaskGreen: '#163320',
    UnitTaskRed: '#140001',
}

const AosColors = {
    PrimaryLight: '#417eff',
    Primary: '#005de5',
    PrimaryDark: '#0f4aca',
    PrimaryPale: '#7ca6ff',
    FontColor: '#FFFFFF',
    FontLightColor: '#35427C',
    FontInvertColor: '#000000',
    WidgetFontColor: '#FFFFFF',
    Background: '#0b1029',
    BottomTabsBackground: '#0b1029',
    LightBackground: '#182146',
    DarkBackground: '#232d43',
    WidgetBackground: '#232d43',
    FlightsScreenAirportSelectBackground: '#005de5',
    PrimaryButtonText: '#FFF',
    ProgressBarBackground: 'rgba(24, 33, 70, 0.4)',
    ProgressBar: '#FFF',
    ReportingItem: '#182146',
    MenuButtonColor: '#6780aa', //UiBlack4
    SideMenuInactive: '#6780aa',
    WidgetSecondary: '#69728B',
    WidgetPrimary: '#FFFFFF',
    TabBarActive: '#fff',
    TabBarInactive: '#000',
    ChartBase: '#3b4b6a', //UiBlack3
    SmartColor: '#FFF',
    DashboardItemBackground: '#1B2435',
    DisabledText: '#c6c7cb', //Grey2
    TimelineBackground: '#14202c', // UiBlack1
    FlightGrey: '#879dc1',
    ActiveGrey: '#2D3952',
    ContactListItem: '#20293A',
    SecondaryText: '#838FAE',
    ToastFont: '#232324',
    ChecklistBackground: '#18202a',
}

const AoimsColors = {
    PrimaryLight: '#FFE270',
    Primary: '#FFDD4F',
    PrimaryDark: '#b08f22',
    PrimaryPale: '#2D2D2D',
    FontColor: '#0F0F0F',
    FontLightColor: '#B9B9B9',
    FontInvertColor: '#F0F0F0',
    Background: '#0F0F0F',
    BottomTabsBackground: '#2D2D2D',
    LightBackground: '#FFDD4F',
    WidgetBackground: '#2D2D2D',
    DarkBackground: '#2D2D2D',
    WidgetFontColor: '#B9B9B9',
    FlightsScreenAirportSelectBackground: '#2D2D2D',
    PrimaryButtonText: '#0F0F0F',
    ProgressBarBackground: '#FFF1B8',
    ProgressBar: '#1C1C1C',
    ReportingItem: '#2D2D2D',
    MenuButtonColor: '#FFFFFF',
    SideMenuInactive: '#8b8b8b',
    WidgetSecondary: '#B9B9B9',
    WidgetPrimary: '#EAEAEA',
    TabBarActive: rgba('#000', 1),
    TabBarInactive: rgba('#000', 0.73),
    ChartBase: '#5a5a5a',
    SmartColor: '#000',
    DashboardItemBackground: '#2D2D2D',
    DisabledText: '#c6c7cb',
    TimelineBackground: '#0F0F0F',
    FlightGrey: '#B9B9B9',
    ActiveGrey: '#5a5a5a',
    ContactListItem: '#2D2D2D',
    SecondaryText: '#B9B9B9',
    ToastFont: '#232324',
    ChecklistBackground: '#18202a',
}

const ColorConfig: Record<string, typeof AosColors> = {
    AOS: AosColors,
    AOIMS: AoimsColors,
}

export const Color = {
    ...BaseColors,
    ...ColorConfig[appConfig.theme],
}

export const statusBarColor = {
    AOS: 'light-content',
    AOIMS: 'dark-content',
}[appConfig.theme]

export type Color =
    | (typeof BaseColors)[keyof typeof BaseColors]
    | (typeof AosColors)[keyof typeof AosColors]
    | (typeof AoimsColors)[keyof typeof AoimsColors]
